import './App.css';
import BO from './compo/BO';
function App() {
  return (
    <div className="App">


    <BO></BO>
       
        
    </div>
  );
}

export default App;
